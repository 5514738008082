<!--
File: Roads.vue
Description: show list of roads entered in the DB.
-->
<template>
  <base-data-table v-if="eligible" :loading="showSpinner" :pagination="pagination"
    :btnAddAllowed="isBtnAllowed('AddButton')" :from="from" :to="to" :total="total" :showCloseBtn="!!closePath"
    @add-item="viewItem()">

    <template #header-filters>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <RoadClassesDropdown v-model="selectedRoadClass" @input='reloadData' />
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <search-input v-model="searchQuery" />
      </div>
    </template>

    <template #table-rows>
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}" :class="{ blinking: item[idFieldName] === highlightedRow }"
          :id="item[idFieldName] === highlightedRow ? 'highlightedRow' : null">

          <md-table-cell v-for="header in headers" :key="header.value" :md-label="$t(header.text)"
            :md-sort-by="header.sortable ? header.value : null" :md-numeric="header.format === 'number'">
            <template v-if="header.condition">
              <a href='' @click.stop.prevent="header.onClick(item.road_id)"> {{ item[header.value] }} </a>
            </template>
            <template v-else>
              {{ item[header.value] }}
            </template>
          </md-table-cell>

          <md-table-cell :md-label="$t('tables.actions')">
            <table-actions :btnEditAllowed="isBtnAllowed('EditButton')" :btnDeleteAllowed="isBtnAllowed('DeleteButton')"
              @edit="viewItem(item[idFieldName])" @delete="deleteItem(item[idFieldName], item.road_description)" />
          </md-table-cell>
        </md-table-row>
      </md-table>
    </template>

    <template #editing-dialog>
      <EditForm v-if="showDetailsDlg" :roadId="currentId" @close="showDetailsDlg = false" />
    </template>
  </base-data-table>
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import { RoadClassesDropdown } from '@/pages/Components'
  import BaseDataTable from "@/pages/Components/BaseDataTable.vue"
  import BaseTableMixin from '@/mixins/BaseTableMixin'
  import SearchInput from "@/pages/Components/SearchInput.vue"
  import TableActions from "@/pages/Components/TableActions.vue"
  import EditForm from './RoadEditForm.vue'

  export default {
    name: 'roads-list',
    mixins: [BaseTableMixin],

    data() {
      return {
        // Переопределяем только специфичные для компонента данные
        formName: 'Roads',
        idFieldName: 'road_id',
        propsToSearch: ['road_description', 'road_class', 'road_number', 'length_m'],
        currentSort: 'road_number',

        selectedRoadClass: '',
        selectedRoad: null, // for showing sections belonging to the road

        historyMapping: {
          selectedRoadClass: '',
          currentSort: 'road_number',
          currentSortOrder: 'asc',
          pagination: this.pagination,
          searchQuery: ''
        }
      }
    },
    components: {
      BaseDataTable,
      SearchInput,
      TableActions,
      RoadClassesDropdown,
      EditForm,
    },

    methods: {
      ...mapActions({
        loadList: 'LOAD_ROAD_LIST',
        delete: 'DELETE_ROAD',
      }),

      handleSectionsClick(item) {
        this.selectedRoad = item;
        this.saveHistory2(['selectedRoad'], 'Sections');
        this.$router.push('/inventory_data/sections');
      },

      save_history() {
        this.saveHistory2(Object.keys(this.historyMapping));
      },
    },

    computed: {
      ...mapState({
        tableData: (state) => state.RoadNetwork.road_list,
        highlightedRow: (state) => state.History.row
      }),

      ...mapGetters(['closePath']),
      /*
                <md-table-cell :md-label="$t('road_network.length')" md-sort-by="length_m">
                  {{ item.length_m | numFormat(0) }}
      */
      headers() {
        return [
          { text: "road_network.road_class", value: "road_class", sortable: true },
          { text: "road_network.road_number", value: "road_number", sortable: true },
          { text: "stdCols.name", value: "road_description", sortable: true },
          { text: "road_network.length", value: "length_m", sortable: true, format: "number" },
          {
            text: "road_network.section_count", value: "section_count", sortable: true, condition: "item.section_count > 0",
            onClick: this.handleSectionsClick, format: "number"
          },
        ]
      }
    },

    watch: {
      selectedRoadClass(newVal) {
        this.payload = {road_class: newVal};
        this.reloadData();
      }
    }
  }
</script>
<style lang="scss" scoped>
@import '@/mixins/BaseTable.scss';
</style>